@import url(https://rsms.me/inter/inter.css);
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  font-family: Inter,sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Inter ,monospace;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.ant-row.ant-form-item
.ant-col.ant-form-item-control
.ant-form-item-control-input
.ant-form-item-control-input-content
.ant-select.ant-select-borderless
.ant-select-selector {
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
}

@media (max-width: 1024px) {
  .ant-row.ant-form-item
  .ant-col.ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select.ant-select-borderless
  .ant-select-selector {
    border: 1px solid #dae0e6;
    box-sizing: border-box;
    border-radius: 6px;
  }
}

.ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error
.ant-col.ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select.ant-select-borderless
  .ant-select-selector {
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
}

@media (max-width: 1024px) {
  .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error
  .ant-col.ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select.ant-select-borderless
  .ant-select-selector {
    border: 1px solid #dae0e6;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .ant-list-vertical .ant-list-item-action {
    margin-top: 0 !important;
  }
}

.ant-input-number-handler-wrap {
  visibility: hidden;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-collapse-arrow {
  margin-top: 1em;
}

.capitalize-first-letter {
  text-transform:capitalize;
}

.livlet-navigation {
  margin: 0!important;
}

.livlet-navigation .ant-menu-submenu-arrow {
  display: none!important;
}

.livlet-navigation .ant-menu-submenu-title {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
          column-gap: 1.25rem;
  font-weight: 600;
  margin: 0!important;
  padding: 1rem 1.5rem!important;
  height: auto!important;
  color: #45655E;
}

.livlet-navigation .ant-menu-submenu-title span {
  width: 100%!important;
}

.livlet-submenu-bordered {
  border-bottom: 1px solid #EFF1F5!important;
}

.livlet-navigation .ant-menu-submenu-inline > .ant-menu-submenu-title :hover,
.livlet-navigation .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title {
  color: #009966!important;
}

.livlet-navigation .ant-menu-submenu-selected {
  color: #009966!important;
}

.livlet-navigation .ant-menu-submenu-inline > .ant-menu-submenu-title:hover svg path {
  stroke: #009966;
}

.livlet-navigation .ant-menu-submenu-inline .ant-menu-submenu-arrow {
  color: #C7C9C7!important;
}

.livlet-navigation ul.ant-menu {
  background: white;
  margin-left: 2rem;
  border-left: 1.5px solid #F5F6FA;
  width: auto;
}

.livlet-navigation li.ant-menu-item {
  width: 100%;
  color: #8E8E89;
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0!important;
  padding-left: 1.25rem!important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-right: 0!important;
}

.livlet-navigation li.ant-menu-item svg {
  margin-right: 0.75rem;
}

.livlet-navigation li.ant-menu-item-selected {
  color: black;
}

.livlet-navigation .ant-menu-submenu-inline > .ant-menu-submenu-title :hover svg path,
.livlet-navigation .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title svg path {
  stroke: #009966;
}

.ant-menu-item-selected {
  background: white !important;
  transition: none !important;
}

.ant-menu-item::after {
  border: none !important;
  transition: none !important;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: white !important;
}

.active-menu-fill.ant-menu-item-selected span span.ant-badge, p.ant-scroll-number-only-unit current {
  color: white!important;
}

.active-menu-stroke.ant-menu-item-selected, .active-menu-fill.ant-menu-item-selected {
  border-left: 4px solid #00B74F;
}

.active-menu-stroke.ant-menu-item-selected span, .active-menu-fill.ant-menu-item-selected span,
.active-menu-stroke.ant-menu-item-active span, .active-menu-fill.ant-menu-item-active span {
  color: #00B74F;
  font-weight: 600;
}

.active-menu-stroke.ant-menu-item-active svg path{
  stroke: #00B74F;
}

.active-menu-stroke.ant-menu-item-selected svg path{
  stroke: #00B74F;
}

.active-menu-fill.ant-menu-item-active svg path{
  fill: #00B74F;
}

.active-menu-fill.ant-menu-item-selected svg path{
  fill: #00B74F;
}

p.ant-scroll-number-only-unit {
  color: white!important;
}

.mobile-navigation-drawer .ant-drawer-body {
  padding-left:0 !important;
}

.mobile-navigation-drawer .ant-menu-inline {
  border-right: none;
}

.expenses-theme-icon path {
  fill: #E974C1;
}

.works-theme-icon path{
  fill: #7479E9;
}

.works-stroke-theme-iconworks-stroke-theme-icon path {
  stroke: #7479E9;
}

.notes-theme-icon path{
  fill: #21272B;
}

.contacts-theme-icon path{
  fill: #21272B;
}

.contacts-stroke-theme-icon path{
  stroke: #21272B;
}

.search-theme-icon, .search-stroke-theme-icon {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.search-theme-icon path{
  fill: #21272B!important;
}

.search-stroke-theme-icon path{
  stroke: #21272B !important;
}

.inventory-theme-icon path{
  fill: #EFC45D;
}

.inventory-stroke-theme-icon path{
  stroke: #EFC45D;
}

.my-property-theme-icon path{
  stroke: #2A80E1;
}

.improve-property-theme-icon path {
  stroke: #67C18B;
}

@media (min-width: 1024px) {
  .ant-drawer-content-wrapper {
    border-left: 32px solid rgba(0, 0, 0, .45);
  }
}

.header-search input::-webkit-input-placeholder {
  color: #6B7185;
}

.header-search input:-ms-input-placeholder {
  color: #6B7185;
}

.header-search input::placeholder {
  color: #6B7185;
}

.header-search.ant-input-affix-wrapper-focused {
  background-color: #fff !important;
}

.header-search.ant-input-affix-wrapper-focused input {
  background-color: #fff !important;
  color: #21272B;
}

.ant-popover-inner {
  background-color: rgba(0,0,0,0) !important;
}

.ant-popover-inner-content {
  padding: 0 !important;
  border-radius: 6px;
}

.ant-popover-content {
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  background: #fff;
}

.smallTooltipPopover .ant-popover-content {
  border-radius: 4px !important;
}

.smallTooltipPopover .ant-popover-inner-content {
  border-radius: 4px !important;
}

.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  border-left: 5px solid #236EC2 !important;
  border-bottom: 5px solid #236EC2 !important;
}

.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 5px !important;
  border-top: 1px solid #dae0e6 !important;
  border-left: 1px solid #dae0e6 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top: 1px solid #dae0e6 !important;
  border-left: 1px solid #dae0e6 !important;
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-bottom: 1px solid #dae0e6 !important;
  border-right: 1px solid #dae0e6 !important;
}

.ant-popover-content > .ant-popover-arrow {
  background: #fff;
}

@media (min-width: 1024px) {
  .no-rounded-list li:hover {
    background: #ECF6FF;
    cursor: pointer;
  }
}

.rounded-top-bottom-list li:hover:first-child{
  border-top: 1px solid #ECF6FF;
  border-top-right-radius: 0.625rem;
  border-top-left-radius: 0.625rem;
}

.rounded-top-bottom-list li:hover{
  background: #ECF6FF;
  cursor: pointer;
}

.rounded-top-bottom-list li:hover:last-child{
  border-bottom: 1px solid #ECF6FF;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.rounded-top-bottom-list li:hover > .ant-tag {
  background: #fff;
}

.rounded-bottom-list li:hover{
  background: #ECF6FF;
  cursor: pointer;
}

.rounded-bottom-list li:hover:last-child{
  border-bottom: 1px solid #ECF6FF;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.rounded-bottom-list li:hover .ant-tag {
  background: #fff;
}


.form-toolkit-popover .ant-popover-title {
  border-bottom: none;
  min-width: 0;
}

.form-toolkit-popover .ant-popover-content {
  font-size: 0.8rem;
  font-weight: 500;
  color: #6B7185 !important;
}

.form-confirm-close-modal .ant-modal-content {
  border-radius: 6px !important;
  z-index: 100!important;
}

.form-confirm-close-modal .ant-modal-confirm-title {
  font-weight: 600 !important;
  color: #21272B !important;
}

.form-confirm-close-modal .ant-modal-confirm-content {
  font-weight: 400 !important;
  color: #6B7185 !important;
}

.form-confirm-close-modal .anticon-exclamation-circle {
  display: block !important;;
}

.form-confirm-close-modal .ant-btn {
  border: 1px solid #DAE0E6 !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  color: #21272B !important;
}

.form-confirm-close-modal .ant-btn:hover {
  color: #21272B !important;
  border: 1px solid #2A80E1 !important;
}

.form-confirm-close-modal .ant-btn-primary {
  border: 1px solid #dae0e6 !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  background: #2A80E1 !important;
  color: #fff !important;
}

.form-confirm-close-modal .ant-btn-primary:hover {
  color: #fff !important;
  background: #236EC2 !important;
}

.dismiss-modal .ant-modal-body {
  padding: 2rem 2rem 1rem 2rem;
  display: -webkit-flex;
  display: flex;
}

.dismiss-modal .ant-modal-footer {
  border: 0;
  padding: 0 2rem 1.5rem 2rem;
}

.dismiss-modal .question-icon {
  color: #faad14;
  font-size: 1.375rem;
}

.dismiss-modal .info-icon {
  color: #1890ff;
  font-size: 1.375rem;
}

.dismiss-modal button.ant-btn {
  /*display: none;*/
}

.dismiss-modal div.ant-modal-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.dismiss-modal button.ant-btn-primary {
  display: -webkit-flex;
  display: flex;
}

.dismiss-modal p {
  color: #21272B;
  font-size: 1rem;
  font-weight: 600;
}

.dismiss-modal .description {
  font-size: 0.875rem;
  font-weight: 400;
}

.form-title-error {
  border: 1px solid red !important;
  border-radius: 6px !important;
  }


.highlight-from-search {
  -webkit-animation: blinkingBackground 1s ease-in-out 2;
          animation: blinkingBackground 1s ease-in-out 2;
}

@-webkit-keyframes blinkingBackground {
  0%  { background-color: #F0F2F5;}
  25%  { background-color: #EFF1F5;}
  50%  { background-color: #DAE0E6;}
  75%  { background-color: #EFF1F5;}
  100%  { background-color: white;}
}

@keyframes blinkingBackground {
  0%  { background-color: #F0F2F5;}
  25%  { background-color: #EFF1F5;}
  50%  { background-color: #DAE0E6;}
  75%  { background-color: #EFF1F5;}
  100%  { background-color: white;}
}

.check-icon-hoverable {
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  height: 1.8rem;
  width: 1.8rem;
}

.check-icon-hoverable:hover {
  opacity: 1;
}

div.ant-popover-buttons {
  padding-bottom: 0.75rem;
  margin-bottom: 0;
  text-align: center;
  box-shadow: none;
}

div.ant-popover-buttons button {
  width: 5rem!important;
  height: 2rem!important;
  text-align: center!important;
  border-radius: 6px;
}

.ant-picker, .ant-picker-input, .ant-picker-focused {
  box-shadow: none!important;
  caret-color: transparent;
  background: none!important;
}

.ant-picker-clear {
  background: none!important;
}

.ant-switch-checked {
  background: #009966!important;
}

.selected-pill {
  color: white!important;
  font-weight: 600!important;
  background: #2A80E1!important;
}

.green-pill:hover {
  color: #67C18B!important;
}

.green-pill-selected {
  color: white!important;
  font-weight: 600!important;
  background: #67C18B!important;
}

.frequency-selector > .ant-select-selector {
  display: -webkit-flex;
  display: flex;
  vertical-align: center;
  width: 60%;
  border: 1px solid #dae0e6;
  background: #F0F2F5!important;
  color: #21272b;
  border-radius: 6px!important;
  height: 3rem!important;
  padding-top: 0.6rem!important;
  font-size: 1rem;
}

.frequency-selector > .ant-select-selection-item {
  display: -webkit-flex;
  display: flex;
  vertical-align: center;
}

.expenses-top-level-category-selected {
  background: #2A80E1 !important;
  font-weight: 600;
  color: #fff !important;
}

.improvements-sort-icon-selected path {
  fill: #2A80E1;
}

.improvements-sort-icon-notselected path {
  fill: #6B7185;
}

.improvements-sort-selected {
  background: #2A80E1 !important;
  font-weight: 600;
  color: #fff !important;
}

.header-icon path {
  stroke: #6B7185;
}

.eco-icon path {
  stroke: #67C18B;
}

.popup-sticky {
  position: absolute;
  bottom: 0;
}

@media (max-width: 1024px) {
  .display-desktop {
    display: none;
  }

  .upkeep-mobile {
    float: right;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
}

@media (min-width: 1024px) {
  .display-mobile {
    display: none;
  }

  .upkeep-mobile {
    display: none;
  }
}

@media (max-width: 730px) {
  .costs-desktop {
    display: none;
  }
}

@media (min-width: 730px) {
  .costs-mobile {
    display: none;
  }
}

@media (max-width: 1024px) {
  .todo-desktop {
    display: none;
  }

  .todo-description {
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .todo-mobile {
    display: none;
  }

  .todo-description {
    padding-right: 4.5rem;
  }
}

.livlet-logo {
  width: 138px;
  height: 32px;
}

@media (min-width: 700px) {
  .epc-widget {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
  }

  .epc-property-value {

    margin-left: 0.5rem;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
}

@media (max-width: 700px) {
  .epc-widget {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .epc-property-value {
    margin-left: 0.5rem;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .epc-potential-rating {
    margin-top: 0.5rem;
  }
}

.question-content {
  position: absolute;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
  opacity: 0;
  height: 0;
  transition: all .5s ease-in-out;
}

.question-wrapper:hover > .question-content {
  transition: all .5s ease;
  height: 2.5rem;
  opacity: 1;
}

.question-wrapper:hover .question-header svg {
  width: 2.25rem;
  height: 2.25rem;
}

.question-wrapper svg {
  margin-bottom: 0.5rem;
}

.question-wrapper {
  height: 14.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.question-narrow {
  width: 10.563rem!important;
}

@media (min-width: 1024px) {
  .cost-filter {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .cost-filter {
    width: 100%;
  }

  .filter-button {
    margin-top: -1rem;
    margin-bottom: 1.5rem;
  }
}

.pill-active {
  color: white;
  background: #2A80E1;
  font-weight: 600;
}

.custom-spinner {
  -webkit-animation-name: spin;
          animation-name: spin;
  -webkit-animation-duration: 3000ms;
          animation-duration: 3000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
  to {
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
  to {
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg);
  }
}

.arrow-gray {
  margin-left: 0.25rem;
}

.arrow-gray path {
  stroke: #6B7185;
}

.small-tags span.ant-tag {
  height: 1.25rem;
  padding: 0 0.3rem;
}

.tag-white {
  background: white!important;
}

.tag-blue {
  background: #2A80E1!important;
  color: white!important;
  font-weight: 600!important;
}

.tag-gray {
  background: rgba(130, 130, 130, 0.16)!important;
  color: #828282!important;
  font-weight: 600!important;
}

.tag-royalblue {
  background: #2E2D68!important;
  color: #EBF9FF!important;
}

.tag-white {
  background: white;
  color: #666F7A;
  font-weight: 600!important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.tag-select .anticon {
  margin-top: 0!important;
  margin-right: 0!important;
}

div.ant-drawer-body {
  padding-bottom: 0;
}

.social-login-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 1.75rem auto;
  grid-column-gap: 1.75rem;
  -webkit-column-gap: 1.75rem;
          column-gap: 1.75rem;
}

.social-login-button {
  padding: 0.75rem!important;
  text-align: center!important;
  border: 1px solid #2A80E1!important;
  font-size: 1rem!important;
  line-height: 1.5rem!important;
  border-radius: 6px!important;
  background: white!important;
  color: #2A80E1!important;
  height: 3.125rem!important;
  width: 17rem!important;
  box-shadow: none!important;
  font-family: Inter, sans-serif!important;
  font-weight: 600!important;
  cursor: pointer!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  display: -webkit-flex!important;
  -webkit-justify-content: center!important;
  -webkit-align-items: center!important;
  -ms-justify-content: center!important;
  -ms-align-items: center!important;
}

@media (max-width: 1024px) {
  .social-login-wrapper {
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    width: 100%;
  }
}

.social-login-button:hover {
  color: #1890ff!important;
  border: 1px solid #65aaf7!important;
}

@media (max-width: 600px) {
  .social-login-button {
    width: 100% !important;
  }

  .social-login-wrapper {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 800px) {
  .ant-select-selection-item {
    max-width: 250px!important;
  }
}

.record-as-invoice-button {
  font-weight: 600;
  padding: 0 1rem;
  color: #fff;
}

.ghost-modal-button {
  padding: 0!important;
  box-shadow: none!important;
}

.calendar-column-bordered {
  border-right: 1px solid #DAE0E6;
  /*1px solid #dae0e6;*/
}

@media (max-width: 700px) {
  .calendar-column-bordered {
    border-right: 0;
  }
}

.calendar-navigation {
  height: 1.625rem;
}

.calendar-navigation:hover svg path{
  fill: #6B7185;
  transition: fill 500ms;
}

/*header height*/

.page-header {
  height: 6rem!important;
}

.header-questions {
  display: none!important;
}

@media (max-width: 1024px) {
  .page-header {
    height: 8.5rem!important;
  }
}

.single-page-pagination button.ant-btn {
  display: none;
}

.tag-icon-blue svg path {
  fill: #236EC2!important;
}

.search-keyword-active {
  background: #2A80E1;
  border-radius: 4px;
  color: #EBF9FF!important;
  transition: background .5s ease, color .5s ease;
}

.add-tag-active {
  color: #666F7A!important;
}

.add-tag-active svg path {
  fill: #666F7A!important;
}

@media (max-width: 1024px) {
  .button-desktop {
    display: none!important;
  }
}

.account-item:hover {
  background: #F5F6FA;
  color: #21272B;
  transition: background 0.5ms ease;
}

.account-item-stroke:hover svg path {
  stroke: #21272B;
}

.account-item-fill:hover svg path {
  fill: #21272B;
}

.cost-date-row {
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

@media (max-width: 1024px) {
  .cost-date-row {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  /*.cost-date-filter {*/
  /*  padding-bottom: 1rem;*/
  /*  border-bottom: 1px solid #dae0e6;*/
  /*  margin-bottom: 1rem;*/
  /*}*/
}

.tag-select .ant-select-selection-item {
  background: rgba(130, 130, 130, 0.16)!important;
  color: #828282!important;
  padding: 0.188rem 0.5rem!important;
  border-radius: 4px!important;
  font-weight: 600!important;
  font-size: 0.75rem!important;
  line-height: 1.125rem!important;
  border: none!important;
  cursor: pointer!important;
  height: 1.625rem!important;
}

.upkeep-tag-select .ant-select-selector {
  border-radius: 6px!important;
  padding: 10px 12px!important;
}

.upkeep-tag-select {
  width: 100%;
}

.upkeep-tag-select .ant-select-selection-item {
  background: #2A80E1!important;
  color: white!important;
  padding: 0.188rem 0.5rem!important;
  border-radius: 4px!important;
  font-weight: 600!important;
  font-size: 0.875rem!important;
  line-height: 1.125rem!important;
  border: none!important;
  cursor: pointer!important;
  height: 1.5rem!important;
}

.upkeep-tag-select .ant-select-selection-placeholder {
  padding-left: 8px!important;
}

.upkeep-tag-select .ant-select-selection-item svg {
  width: 13px!important;
  height: 13px!important;
}

.upkeep-tag-select .ant-select-selection-item svg path {
  fill: white!important;
}

.upkeep-tag-select .ant-select-item-option {
  font-weight: 500!important;
  font-size: 1rem!important;
  line-height: 1.5rem!important;
  color: #21272B!important;
  height: 3rem!important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.upkeep-content .ant-select-item-option-content {
  color: #00CC33!important;
}

.upkeep-tag-select .ant-select-item-option-active, .upkeep-tag-select .ant-select-item-option-selected {
  font-weight: 700!important;
  background: #EBF9FF!important;
  margin-right: 1rem;
}

.upkeep-tag-select .ant-select-item-option-selected svg {
  display: none!important;
}

.dashboard-info-icon:hover .info-icon-circle {
  fill: #236EC2!important;
  transition: fill .5s ease;
}

.dashboard-info-icon:hover .info-icon-letter-fill {
  fill: white;
  transition: fill .5s ease;
}

.dashboard-info-icon:hover .info-icon-letter-stroke {
  stroke: white!important;
  transition: stroke .5s ease;
}

.search-filters {
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.error-field {
  border: 1px solid #d1352a!important;
  /*box-shadow: 0 0 1px 0 rgba(235, 64, 52, 0.3)!important;*/
  box-shadow: none!important;
}

.attachment-preview-modal .ant-modal-confirm-content {
  margin-top: 0!important;
}

.attachment-preview-modal .anticon-check-circle {
  display: none!important;
}

.attachment-preview-modal .ant-modal-confirm-btns {
  display: none!important;
}

.upkeep-selected-tab {
  color: #2A80E1!important;
  background: white!important;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.upkeep-tabs-arrow-disabled svg path {
  stroke: #DAE0E6;
}

.upkeep-tabs-arrow:hover svg path {
  stroke: #2A80E1;
  transition: stroke .5s ease;
}

/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none!important;
}

/* Branding on the widget */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none!important;
}

/* Hiding reCaptcha v3 badge in the bottom right corner */
.grecaptcha-badge {
  visibility: hidden;
}
.icon-green path {
  fill: #00B74F;
}

.yellow-icon path {
  stroke: #FEC22D;
}

@media (max-width: 750px) {
  .partners-placeholder { display: none!important; }
}

.ant-list-empty-text { margin: 0; }

.adm-popup-body-position-bottom {
  left: auto!important;
  right: 0!important;
  width: 680px!important;
}

@media (max-width: 1024px) {
  .adm-popup-body-position-bottom {
    width: 100%!important;
  }
}

@media (max-width: 768px) {
  .livlet-pdf-page canvas {
    max-width: 100%;
    height: auto!important;
  }
}

img.circle-image-cropped {
  width: 3rem;
  height: 3rem;
  object-fit:cover;
  border-radius:50%;
}

.handholding .ant-tooltip-arrow { display: none!important; }

.planner-date-picker {
  background: white!important;
  border: 1px solid #DAE0E6!important;
  border-radius: 6px!important;
  height: 2.5rem;
  width: 100%;
}

@media (max-width: 768px) {
  .ant-layout-footer { display: none; }
}
